var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"font-2",domProps:{"innerHTML":_vm._s(
			_vm.$lang(
				'<b>Si aún no has realizado el pago</b> o quieres volver a intentarlo, <b>haz click en el siguiente botón</b> y serás redirigido a la pantalla de pago.'
			)
		)}}),_c('div',{staticClass:"py-5"},[_c('Button',{attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.payOrder}},[_vm._v(" "+_vm._s(_vm._f("lang")('Pagar con Getnet'))+" ")])],1),_c('div',{staticClass:"font-1",domProps:{"innerHTML":_vm._s(
			_vm.$lang(
				'Te solicitamos que <b>realices el pago dentro de las 72 hs.</b> de haber confirmado tu compra. Luego de ese plazo tu orden será cancelada.'
			)
		)}})])}
var staticRenderFns = []

export { render, staticRenderFns }