<script>
export default {
	lang: 'shop',
	inject: ['xcheckout'],
	props: ['data'],
	data() {
		return {
			clndni: null,
			clncard: null,
			loading: false,
		}
	},
	computed: {
		checkout() {
			return this.xcheckout()
		},
	},
	methods: {
		async submit() {
			this.loading = true
			await this.checkout.stepSubmit({
				customDiscountOption: {
					key: 'cln',
					input: {
						clncard: this.clncard,
						clndni: this.clndni,
					},
				},
			})
			this.loading = false
		},
	},
	mounted() {
		let { clndni, clncard } = this.checkout.stepsData.discount.payload?.customDiscountOption?.input || {}
		this.clndni = clndni
		this.clncard = clncard
	},
}
</script>

<template>
	<CardLayout :clickable="false">
		<div class="font-weight-bold pb-2">Club La Nación</div>
		<div class="pb-4">
			{{ 'Ingresa los datos de tu tarjeta Club La Nación' | lang }}<br />
			<small>{{ 'No acumulable con otras promociones' | lang }}</small>
		</div>
		<div>
			<v-form @submit.prevent="submit()">
				<Validator :validation="checkout.validation">
					<v-row>
						<v-col cols="12">
							<TextField
								v-model="clndni"
								:label="$lang('DNI')"
								:placeholder="$lang('Ingresa el DNI del titular de la tarjeta')"
							/>
						</v-col>
						<v-col cols="12">
							<TextField
								v-model="clncard"
								:label="$lang('Número de Tarjeta')"
								:placeholder="$lang('Ingresa el número impreso en tu tarjeta')"
							/>
						</v-col>
						<v-col cols="12" class="d-flex">
							<v-spacer />
							<Button color="success" @click="submit()" :loading="loading">
								{{ 'Aplicar' | lang }}
							</Button>
						</v-col>
					</v-row>
				</Validator>
			</v-form>
		</div>
	</CardLayout>
</template>
