<script>
export default {
	props: ['data'],
}
</script>

<template>
	<CardLayout @cardClick="$emit('optionSelected')" clickable>
		<GetnetCardContent />
	</CardLayout>
</template>
