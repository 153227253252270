<script>
import { componentExists, filterComponents } from '@/site/autoloader'

export default {
	lang: 'shop',
	inject: ['xcheckout'],
	data() {
		return { checkbox: false }
	},
	computed: {
		checkout() {
			return this.xcheckout()
		},
		confirmCardsComps() {
			return filterComponents(/^CheckoutConfirmCard/)
				.map((name) => ({
					name,
					stepKey: name.split('-', 2)[1],
				}))
				.sort(
					(a, b) =>
						this.checkout.stepsKeys?.indexOf(a.stepKey) - this.checkout.stepsKeys?.indexOf(b.stepKey)
				)
		},
		paymethodConfirmBtnComp() {
			let name = `${this.checkout.order.payments[0].methodKey}-CheckoutConfirmButton`
			return componentExists(name) && name
		},
	},
	methods: {
		async submit() {
			let { success, data } = await this.checkout.stepSubmit()
			if (success) {
				this.checkout.loadingSubmit = true
				let url = data.stepResponse?.redirect
				if (!url) {
					url = this.$router.resolve({
						name: 'user.order-detail',
						params: { id: data.checkout.order.id },
					}).href
				}
				window.location = url
			}
		},
	},
}
</script>

<template>
	<CheckoutStepLayout :title="$lang('Confirma tu Compra')" show-mobile-expanded-summary>
		<component
			v-for="{ name, stepKey } of confirmCardsComps"
			:key="name"
			:is="name"
			:link-text="$lang('Modificar')"
			@linkClick="checkout.gotoStep(stepKey)"
		/>
		<template #below-summary>
			<div class="mt-6">
				<div class="d-flex mb-3">
					<v-checkbox v-model="checkbox" />
					<span>
						Estoy de acuerdo con los
						<SafeLink to="/terminos-y-condiciones" target="_blank">Términos y Condiciones</SafeLink>
						y con las
						<SafeLink to="/cambios-y-devoluciones" target="_blank">
							Políticas de cambio y devoluciones
						</SafeLink>
					</span>
				</div>
				<div :class="{ 'disabled-confirm': !checkbox }">
					<component v-if="paymethodConfirmBtnComp" :is="paymethodConfirmBtnComp" />
					<Button
						v-else
						color="success"
						x-large
						block
						@click="submit()"
						:loading="checkout.loadingSubmit"
						:class="{ 'rounded-0 bottom-bar': $vuetify.breakpoint.xs }"
					>
						{{ 'Realizar Pago' | lang }}
					</Button>
				</div>
			</div>
		</template>
	</CheckoutStepLayout>
</template>
<style scoped>
.bottom-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	cursor: pointer;
}
.disabled-confirm {
	opacity: 0.7;
}
.disabled-confirm,
.disabled-confirm *,
.disabled-confirm ::v-deep * {
	pointer-events: none !important;
}
</style>
