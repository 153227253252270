<script>
import goCuotasLogo from './gocuotas-logo.png'

export default {
	data() {
		return {
			goCuotasLogo,
		}
	},
}
</script>

<template>
	<CheckoutPaymentCardContent :logo-src="goCuotasLogo" title="GoCuotas">
		<v-list>
			<v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-chevron-right</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0">
					<div>Pagá con cualquier <b>Tarjeta de Débito en cuotas</b></div>
				</v-list-item-content>
			</v-list-item>
			<v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-chevron-right</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0">
					<div>Todos los días <b>hasta 3 cuotas sin interés</b></div>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</CheckoutPaymentCardContent>
</template>
